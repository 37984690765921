// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Error from './pages/Error';
import Header from './components/Header';
import Footer from './components/Footer';
import WhatsAppButton from './components/whatsapp';
import CitySelector from './pages/CitySelector';
import './App.css';

function App() {
  const [isCitySelected, setIsCitySelected] = useState(false);

  const handleCitySelect = (city) => {
    console.log('Cidade selecionada:', city); 
    setIsCitySelected(true); 
  };

  return (
    <HelmetProvider>
      <Router>
        {!isCitySelected ? (
          <CitySelector onSelectCity={handleCitySelect} /> 
        ) : (
          <>
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/services" element={<Services />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="*" element={<Error />} />
            </Routes>
            <WhatsAppButton />
            <Footer />
          </>
        )}
      </Router>
    </HelmetProvider>
  );
}

export default App;

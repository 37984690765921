import React from 'react';
import './PricingSection.css';
import StreamingButton from './StreamingButton';
import SolidityChart from './speedometer'; 
import MaxIcon from '../../assets/Max.png';
import DisneyIcon from '../../assets/Disney.png';
import DeezerIcon from '../../assets/Deezer.png';

const sendMessage = (megas) => {
  const message = `Olá! Tenho interesse no plano de ${megas} Megas. Poderia me passar mais informações sobre?`;
  const encodedMessage = encodeURIComponent(message);
  const url = `https://wa.me/31998458433?text=${encodedMessage}`;
  window.open(url, '_blank');
};

const plans = [
  {
    title: 'Básico',
    speed: 250,
    price: '69,90',
    color1: '#3ace84',  // Verde padrão
    color2: '#B2FF8F',  // Verde claro
    features: [
      '250 MB de Upload',
      'Instalação Inclusa',
      '100% Fibra ótica',
      'Wi-fi grátis',
      'Navegação Ilimitada',
    ],
  },
  {
    title: 'Intermediário',
    speed: 500,
    price: '89,90',
    color1: '#3ace84',  // Verde padrão
    color2: '#B2FF8F',  // Verde claro
    features: [
      '250 MB de Upload',
      'Instalação Inclusa',
      '100% Fibra ótica',
      'Wi-fi grátis',
      'Navegação Ilimitada',
    ],
  },
  {
    title: 'Avançado',
    speed: 700,
    price: '119,90',
    color1: '#00AEF9',  // Azul padrão
    color2: '#8FDEFF',  // Azul claro
    principal: true,
    features: [
      '350 MB de Upload',
      'Instalação Inclusa',
      '100% Fibra ótica',
      'Wi-fi grátis',
      'Navegação Ilimitada',
    ],
  },
  {
    title: 'Premium',
    speed: 1000,
    price: '149,90',
    color1: '#3ace84',  // Vermelho para destacar
    color2: '#B2FF8F',  // Tom mais claro de vermelho
    features: [
      '500 MB de Upload',
      'Instalação Inclusa',
      '100% Fibra ótica',
      'Wi-fi grátis',
      'Wifi 6 ou Rede Mesh incluso',
      'Navegação Ilimitada',
    ],
  },
];

function PricingSection() {
  return (
    <section id="plans" className="pricing-section">
      <div className="section-header">
        <h2>Escolha o Plano Ideal para Você</h2>
        <p>
          Na VitaLink, oferecemos planos personalizados para atender suas necessidades.
          Seja para trabalho, entretenimento ou comunicação, encontre o plano que melhor se
          encaixa no seu perfil e aproveite uma internet de qualidade com suporte dedicado e
          inovação.
        </p>
      </div>
      <div className="pricing-cards">
        {plans.map((plan, index) => (
          <div
            key={index}
            className={`pricing-card ${plan.principal ? 'principal-plan' : ''}`}
          >
            <h3 className='card-plan'>Plano</h3>        
            <h3 className='card-type'>{plan.title}</h3>
            <SolidityChart value={plan.speed} color1={plan.color1} color2={plan.color2} />
            <p className="card-internet">{plan.speed}</p>
            <p className="card-mbps">Mbps</p>
            <div className="horizontal-line"></div>
            <p className="card-price">R$ {plan.price}/mês</p>
            <ul className="card-features">
              {plan.features.map((feature, i) => (
                <li key={i}>
                  <div className="check-circle">
                    <div className="check-mark"></div>
                  </div>
                  {feature}
                </li>
              ))}
            </ul>
            <div className={`horizontal-line-with-text ${plan.principal ? 'principal-line' : 'secondary-line'}`}>
              <span>Adicionais</span>
            </div>
            <div className="streaming-buttons">
              <StreamingButton logoSrc={MaxIcon} altText="Logo Max" url="https://www.max.com" />
              <StreamingButton logoSrc={DisneyIcon} altText="Logo Disney" url="https://www.disneyplus.com" />
              <StreamingButton logoSrc={DeezerIcon} altText="Logo Deezer" url="https://www.deezer.com" />
            </div>
            <div className="horizontal-line2"></div>
            <button className="card-button" onClick={() => sendMessage(plan.speed)}>
              Escolher Plano
            </button>
          </div>
        ))}
      </div>
    </section>
  );
}

export default PricingSection;

// src/pages/About.js
import React from 'react';
import { Helmet } from 'react-helmet-async';
import AboutSection from '../components/home/AboutSection';

function About() {
  return (
    <div>
      <Helmet>
        <title>Quem Somos - VitaLink</title>
        <meta name="description" content="Conheça a história e os valores da VitaLink, seu provedor de internet de confiança." />
        <meta name="keywords" content="quem somos, VitaLink, provedor de internet, fibra óptica" />
        <meta name="author" content="VitaLink" />
        {/* Open Graph */}
        <meta property="og:title" content="Quem Somos - VitaLink" />
        <meta property="og:description" content="Saiba mais sobre a nossa missão e valores." />
      </Helmet>
      <AboutSection />
    </div>
  );
}

export default About;

// src/pages/Home.js
import React from 'react';
import { Helmet } from 'react-helmet-async';
import HeroSection from '../components/home/HeroSection';
import FeaturesSection from '../components/home/FeaturesSection';
import AboutSection from '../components/home/AboutSection';
import PricingSection from '../components/home/PricingSection';
import Levels from '../components/home/Levels';
import TestimonialsSection from '../components/home/TestimonialsSection';
import FAQSection from '../components/home/FAQSection';

function Home() {
  return (
    <div>
      <Helmet>
        <title>VitaLink - Internet de Alta Velocidade</title>
        <meta name="description" content="Descubra os melhores planos de internet com a VitaLink. Conecte-se com qualidade, velocidade e suporte dedicado." />
        <meta name="keywords" content="internet, fibra óptica, provedores, VitaLink, alta velocidade" />
        <meta name="author" content="VitaLink" />
        {/* Open Graph */}
        <meta property="og:title" content="VitaLink - Internet de Alta Velocidade" />
        <meta property="og:description" content="Conecte-se com a melhor internet de alta velocidade da VitaLink." />
        <meta property="og:image" content="/social-share-image.png" />
        <meta property="og:url" content="https://vitalink.com.br" />
      </Helmet>
      
      <HeroSection />
      <FeaturesSection />
      <AboutSection />
      <PricingSection />
      <Levels />
      <TestimonialsSection />
      <FAQSection />
    </div>
  );
}

export default Home;
